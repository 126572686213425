import React, { useState } from "react";
import GoogleLogin from "./GoogleLogin";
import FooterLogin from "./FooterLogin";
const sitinLogo = require("./assets/sitin-logo.png");
const iconX = require("./assets/icon-x.png");
const iconTiktok = require("./assets/icon-tiktok.png");

const Footer = () => {
  const [expanded, setExpanded] = useState(false);

  const handleSignupClick = () => {
    setExpanded(true);
  };

  const handleContinueClick = () => {
    // Handle continue action, e.g., form submission or validation
  };

  return (
    <div id="footer">
      {/* <img src={iconX} alt="Icon X" style={{ width: 40 }} />
              <img src={iconTiktok} alt="Icon X" style={{ width: 40 }} /> */}
      <div id="footer-desktop" className="grid">
        <div className="left-column">
          <div className="left-column-content">
            <img id="footer-logo" src={sitinLogo} alt="SITIN logo" />
            <div id="footer-desc">
              SITIN.ai is your AI twin that sits in online meetings for you.
            </div>
            <div id="footer-icons">
              <a href="https://x.com/sitin_ai" target="_blank" rel="noreferrer">
                <img src={iconX} alt="Icon X" style={{ width: 40 }} />
              </a>
              <a
                href="https://www.tiktok.com/@sitin.ai"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={iconTiktok}
                  alt="Icon X"
                  style={{ width: 40, marginLeft: 8 }}
                />
              </a>
            </div>
          </div>
        </div>
        <div id="contact-us" className="center-column">
          <div className="centered-content">
            <div className="row">
              <a
                href="mailto:feedback@sitin.ai"
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
            </div>
            <div className="row">
              <a
                href="https://sitin.ai/privacy-policy.html"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="footer-mobile">
        <div className="responsive-row">
          <div className="responsive-row-item">
            <img id="footer-logo" src={sitinLogo} alt="SITIN logo" />
            <div id="footer-desc">
              SITIN.ai is your AI twin that sits in online meetings for you.
            </div>
          </div>
          <div className="responsive-row-item">
            <a href="https://x.com/sitin_ai" target="_blank" rel="noreferrer">
              <img src={iconX} alt="Icon X" style={{ width: 40 }} />
            </a>
            <a
              href="https://www.tiktok.com/@sitin.ai"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={iconTiktok}
                alt="Icon X"
                style={{ width: 40, marginLeft: 8 }}
              />
            </a>
          </div>
          <div className="responsive-row-item">
            <div className="row">
              <a
                href="mailto:feedback@sitin.ai"
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
            </div>
            <div className="row">
              <a
                href="https://sitin.ai/privacy-policy.html"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="footer-copyright">@2024 Philomuse Limited</div>
      <FooterLogin />
    </div>
  );
};

export default Footer;
