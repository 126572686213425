import React from "react";
import "firebase/auth";
import { app } from "./firebase-config";
import { getAuth, signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Button } from "antd";

const auth = getAuth(app);

const LoggedInStateDisplay = () => {
  const [user, loading, error] = useAuthState(auth);
  const logout = () => {
    signOut(auth);
  };

  if (loading) {
    return (
      <div>
        <p>Initialising User...</p>
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    );
  }
  if (user) {
    return (
      <div id="login-state-display">
        <div>You are already already logged in as:</div>
        <div>{user.email}</div>
        <Button onClick={logout}>Log out</Button>
      </div>
    );
  }
};

export default LoggedInStateDisplay;
