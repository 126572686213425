import React, { useEffect, useRef, useState } from 'react';

const CameraPage = () => {
  const videoRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);

  // Function to initialize camera stream
  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  // Function to capture picture
  const capturePicture = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL('image/png');
        setImageUrl(dataUrl);
      }
    }
  };

  // Start the camera when component mounts
  useEffect(() => {
    startCamera();
  }, []);

  return (
    <div>
      <video ref={videoRef} autoPlay playsInline />
      <button onClick={capturePicture}>Capture Picture</button>
      {imageUrl && (
        <div>
          <img src={imageUrl} alt="Captured Selfie" />
          <button onClick={() => setImageUrl(null)}>Retake Picture</button>
        </div>
      )}
    </div>
  );
};

export default CameraPage;