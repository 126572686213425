import { Button, Image } from "antd";
import VideoPlayer from "./VideoPlayer";
import QueueAnim from "rc-queue-anim";
import DownloadSitinAppButton from "./DownloadSitinAppButton";
// import SubscribeUserEmailButton from "./SubscribeUserEmailButton";

const bgVideo = require("./assets/bg.mp4");
const video1 = require("./assets/video1.mp4");
const video2 = require("./assets/video2.mp4");
const video3 = require("./assets/video3.mp4");
const tile1 = require("./assets/tile1.png");
const tile2 = require("./assets/tile2.png");
const tile3 = require("./assets/tile3.png");
const tile4 = require("./assets/tile4.png");
const iconZoom = require("./assets/icon-zoom.png");
const iconMicrosoftTeams = require("./assets/icon-microsoft-teams.png");
const iconGoogleMeet = require("./assets/icon-google-meet.png");
const iconSlack = require("./assets/icon-slack.png");

export default function PageContent() {
  return (
    <div>
      {/* <div id="bg-video">
        <video
          autoPlay
          playsInline
          loop
          muted
          controls={false}
          disablePictureInPicture
          disableRemotePlayback
        >
          <source src={bgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div> */}
      <div id="top-content">
        <QueueAnim delay={300} ease="easeOutQuart">
          {/* <h1 id="top-content-h1">SITIN.ai attends meetings for you</h1> */}
          <h1 id="top-content-h1">
          SITIN.ai clones your face and attends online meetings for you
          </h1>
          <div className="desc">
            Upload one photo. Wait 10 seconds. Get your AI twin.
          </div>
          <div id="banner-download-button-container">
            <DownloadSitinAppButton />
          </div>
          {/* <div id="subscribe-user-email-button-container">
            <SubscribeUserEmailButton />
          </div> */}
        </QueueAnim>
        <div style={{ marginTop: "24px" }}>
          <VideoPlayer />
        </div>

        <div id="works-with">Works with</div>
        <div id="works-with-app">
          <div className="works-with-app-container">
            <img
              className="works-with-app-icon"
              src={iconZoom}
              alt="Icon Zoom"
            />
            <span className="works-with-app-icon-text">Zoom</span>
          </div>
          <div className="works-with-app-container">
            <img
              className="works-with-app-icon"
              src={iconMicrosoftTeams}
              alt="Icon Slack"
            />
            <span className="works-with-app-icon-text">Teams</span>
          </div>
          <div className="works-with-app-container">
            <img
              className="works-with-app-icon"
              src={iconGoogleMeet}
              alt="Icon Slack"
            />
            <span className="works-with-app-icon-text">Google Meet</span>
          </div>
          <div className="works-with-app-container">
            <img
              className="works-with-app-icon"
              src={iconSlack}
              alt="Icon Slack"
            />
            <span className="works-with-app-icon-text">Slack</span>
          </div>
        </div>
        <div className="desc" style={{ margin: "12px 0 20px 0" }}>
          and anything that allows virtual cameras
        </div>

        <div id="page-content">
          <div className="content-row">
            <div className="video-container2">
              <video
                autoPlay
                playsInline
                preload={false}
                loop
                muted
                controls={false}
                disablePictureInPicture
                disableRemotePlayback
                className="rounded-video"
              >
                <source src={video1} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            <div className="text-container">
              <h1>One-Photo Setup</h1>
              <div className="text-desc">
                Just take one photo and get your AI avatar in 10 seconds.
              </div>
            </div>
          </div>
          <div className="content-row">
            <div className="text-container">
              <h1>Facial Expression Control</h1>
              <div className="text-desc">
                Make your AI twin smile, nod, or show attentiveness with a
                single click.
              </div>
            </div>
            <div className="video-container2">
              <video
                autoPlay
                playsInline
                preload={false}
                loop
                muted
                controls={false}
                disablePictureInPicture
                disableRemotePlayback
                className="rounded-video"
              >
                <source src={video2} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className="content-row">
            <div className="video-container2">
              <video
                autoPlay
                playsInline
                preload={false}
                loop
                muted
                controls={false}
                controlsList="nodownload noplaybackrate"
                disablePictureInPicture
                disableRemotePlayback
                className="rounded-video"
              >
                <source src={video3} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="text-container">
              <h1>Switch between cameras</h1>
              <div className="text-desc">
                Switch in and out of your AI camera seamlessly.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1 id="tile-title">
          Let your AI twin sit in for you, while you multitask away.
        </h1>
      </div>
      <div className="tiles-wrapper">
        <div className="tile">
          <Image className="tile-image" src={tile4} preview={false} />
          <div className="tile-heading">Boring Meetings</div>
          <div className="tile-desc">
            Perfect for scenarios where you don't have to talk.
          </div>
        </div>

        <div className="tile">
          <Image className="tile-image" src={tile1} preview={false} />
          <div className="tile-heading">Company All Hands</div>
          <div className="tile-desc">
            Blend in during large company conferences.
          </div>
        </div>

        <div className="tile">
          <Image className="tile-image" src={tile2} preview={false} />
          <div className="tile-heading">Interviewer Duties</div>
          <div className="tile-desc">
            Have SITIN.ai watch over your interviewee while you multitask on
            other stuff.
          </div>
        </div>

        <div className="tile">
          <Image className="tile-image" src={tile3} preview={false} />
          <div className="tile-heading">Online Classes</div>
          <div className="tile-desc">
            Have SITIN.ai show attentiveness during online classes.
          </div>
        </div>
      </div>
      <div id="bottom-cta">
        <h1>Create your AI twin now</h1>
        <div>
          <DownloadSitinAppButton />
        </div>
      </div>
    </div>
  );
}
