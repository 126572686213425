import React, { useEffect, useState } from "react";
import Header from "./Header";
import PageContent from "./PageContent";
import Footer from "./Footer";
import { getAuth, signInWithCustomToken, signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { app } from "./firebase-config";
import { useLocation } from "react-router-dom";

const auth = getAuth(app);

const Homepage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const passedInToken = queryParams.get("token");

  // log into user with just a token (for user directed here by email) (doesnt work yet)
  // useEffect(() => {
  //   const authenticateWithToken = async (token) => {
  //     try {
  //       // Sign in the user with the passed in token
  //       const userCredential = await signInWithCustomToken(auth, token);
  //       setUser(userCredential.user);
  //       console.log("user: " + userCredential);
  //     } catch (error) {
  //       console.error("Error signing in with custom token:", error);
  //       setError(error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (passedInToken) {
  //     authenticateWithToken(passedInToken);
  //   } else {
  //     setLoading(false);
  //   }
  // }, [passedInToken]);

  // verifying passed in token matches with logged in user
  //   useEffect(() => {
  //     const queryParams = new URLSearchParams(window.location.search);
  //     const passedInToken = queryParams.get("token");
  //     if (passedInToken && !loading && !error) {
  //       // compare passedInToken against current user's firebase auth token
  //       if (passedInToken === user.accessToken) {
  //         console.log("token matches");
  //       } else {
  //         console.log("token doesn't match");
  //       }
  //     }
  //   }, [user, loading, error]);
  return (
    <div>
      <Header />
      <PageContent />
      <Footer />
    </div>
  );
};

export default Homepage;
