import React from "react";
import "firebase/auth";
import { app } from "./firebase-config";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

const auth = getAuth(app);
const login = () => {
  signInWithEmailAndPassword(auth, "gold@bean.com", "goldbean");
};

const EmailLogin = () => {
  const [user] = useAuthState(auth);
  if (!user) {
    return <button onClick={login}>Log in with email</button>;
  }
};

export default EmailLogin;
