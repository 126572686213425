import "./styles/style.scss";
import React, { useEffect } from "react";
import "firebase/auth";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { getFirestore } from "firebase/firestore";
import Homepage from "./Homepage";
import { app } from "./firebase-config";
import LoginPage from "./LoginPage";
import SignupEmailPage from "./SignupEmailPage";
import CameraPage from "./CameraPage";
import UploadPicture from "./UploadFromAlbum";
import ExamplePhotoSplit from "./ExamplePhotoSplit";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
export const db = getFirestore(app);

const App = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get("redirectUrl");
  console.log("redirectUrl", redirectUrl);
  if (redirectUrl) {
    window.location.href = redirectUrl;
  }
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupEmailPage />} />
          <Route path="/camera" element={<CameraPage />} />
          <Route path="/upload" element={<UploadPicture />} />
          <Route path="/split" element={<ExamplePhotoSplit />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
