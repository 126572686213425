import React, { useEffect } from "react";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { app } from "./firebase-config";
import LoggedInStateDisplay from "./LoggedInStateDisplay";
import GoogleLogin from "./GoogleLogin";
import { useLocation } from "react-router-dom";
import { getErrorNumber } from "./firebase-utils";

const provider = new GoogleAuthProvider();
const auth = getAuth(app);

const LoginPage = () => {
  window.collectEvent("login_page_loaded");

  const [user] = useAuthState(auth);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const nonce = queryParams.get("nonce");

  const handleRedirect = (token, email, nonce, error) => {
    const params = new URLSearchParams();
    const tokenEncoded = encodeURIComponent(token);
    const emailEncoded = encodeURIComponent(email);
    const nonceEncoded = encodeURIComponent(nonce);
    const errorEncoded = encodeURIComponent(error);
    params.append("token", tokenEncoded);
    params.append("email", emailEncoded);
    params.append("nonce", nonceEncoded);
    params.append("error", errorEncoded);
    // const everythingEncoded =
    const redirectUrl = `sitin://auth%3F${encodeURIComponent(params)}`; // ensure that the sitin:// part of the URL is not URL encoded
    const url = `/?redirectUrl=${redirectUrl}`;
    // params.append("redirectUrl", redirectUrl);
    // const url = `/?${params.toString()}`;
    // console.log("url: " + url);
    // window.collectEvent("redirect_url", { url: params });
    window.location.href = url;
  };

  const handleGoogleLogin = async () => {
    console.log("handleGoogleLogin");
    window.collectEvent("login_with_google_clicked");
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      console.log("Token is = " + token);

      const user = result.user;
      // console.log("User.email = " + user.email, user.providerId);
      // const idTokenResult = await user.getIdTokenResult();
      // const expirationTime = idTokenResult.expirationTime;
      // console.log("tokenresult", idTokenResult);
      // console.log("Token expires at: " + expirationTime);
      window.collectEvent("login_with_google_success");
      handleRedirect(token, user.email, nonce);
    } catch (error) {
      var errorNumber = getErrorNumber(error.code);
      handleRedirect(undefined, undefined, nonce, errorNumber);
    }
  };

  useEffect(() => {
    // if user is already logged in, redirect to app universal link
    if (user) {
      window.collectEvent("user_already_logged_in");
      handleRedirect(user.accessToken, user.email, nonce, undefined);
    }
  }, [user]);

  return (
    <div id="login-page" className="flex-center">
      <div>
        <LoggedInStateDisplay />
      </div>
      {/* <GoogleLogin /> */}
      {!user && (
        <div>
          <GoogleLogin callback={handleGoogleLogin} />
        </div>
      )}
    </div>
  );
};

export default LoginPage;
