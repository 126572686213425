import React, { useState } from "react";
import GoogleLogin from "./GoogleLogin";

import { getErrorNumber } from "./firebase-utils";
import { db } from "./App";
import {
  doc,
  onSnapshot,
  updateDoc,
  setDoc,
  deleteDoc,
  collection,
  serverTimestamp,
  getDocs,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { useLocation } from "react-router-dom";
import { Button, Divider, Input, message } from "antd";


const emailText = `
<p>In about a minute, you can say goodbye to boring Zoom meetings forever! SITIN.ai clones your face and attends online meetings for you.</p>
<p>Upload a selfie, and we will generate your animated AI avatar that sits in for you during online meetings. </p>
<p>Download our desktop app by clicking on the link on your PC or Mac now: <a href="https://sitin.ai">https://sitin.ai</a></p>
<p>Best regards,<br>SITIN.ai team</p>
`;

const FooterLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // firebase
  const colletionRef = collection(db, "emails");
  const googleAuthProvider = new GoogleAuthProvider();
  const auth = getAuth();

  const queryParams = new URLSearchParams(location.search);
  const nonce = queryParams.get("nonce");
  const [expanded, setExpanded] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSignupClick = () => {
    window.collectEvent("mobile_signup_expand_clicked");
    setExpanded(true);
  };

  const handleClose = () => {
    window.collectEvent("mobile_signup_close_clicked");
    setExpanded(false);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailSubmit = () => {
    window.collectEvent("user_email_submitted");
    if (validateEmail(inputValue)) {
      writeToFirestore(inputValue)
        .then(() => {
          showSuccessMessage();
          setExpanded(false);
          setSubmitted(true);
          window.collectEvent("user_email_submit_success");
        })
        .catch((error) => {
          console.log("Email submission failed, error:", error);
        });

      setTimeout(() => {
        navigate("/");
      }, 5000);
    } else {
      window.collectEvent("user_email_invalid");
      message.open({
        type: "warning",
        content: "Email is invalid",
        duration: 5,
        style: {
          marginTop: "8vh",
        },
      });
    }
  };

  const onGoogleLoginFinish = async (values) => {
    window.collectEvent("signup_with_google_clicked");
    signInWithPopup(auth, googleAuthProvider).then((result) => {
      const user = result.user;
      //   console.log("Google login successful. User:", user);
      writeToFirestore(user.email)
        .then(() => {
          showSuccessMessage();
          window.collectEvent("signup_with_google_success");
        })
        .catch((error) => {
          console.log("Google login failed, error:", error);
        });

      setTimeout(() => {
        navigate("/");
      }, 5000);
    });
  };

  const writeToFirestore = async (email) => {
    const newEmail = {
      //   id: uuidv4(),
      email: email,
      createdAt: serverTimestamp(),
      platform: navigator.platform,
      to: email,
      message: {
        subject: "Welcome to SITIN.ai!",
        // text: emailText,
        html: emailText,
      },
    };
    try {
      const emailRef = doc(colletionRef, newEmail.email);
      await setDoc(emailRef, newEmail);
    } catch (error) {
      console.error(error);
    }
  };

  const showSuccessMessage = () => {
    message.open({
      type: "info",
      content:
        "We will send you an email with a download link shortly. Open the link on your computer to get started with SITIN.ai for free!",
      duration: 5,
      style: {
        marginTop: "40vh",
      },
    });
  };

  return (
    <div id="footer-login" className={`footer ${expanded ? "expanded" : ""}`}>
      {!expanded && !submitted && (
        <button className="signup-button" onClick={handleSignupClick}>
          <div>Sign up - it's free</div>
          <div className="signup-button-desc">No credit card required</div>
        </button>
      )}
      {expanded && (
        <div className="expanded-footer-content">
          <button className="close-button" onClick={handleClose}>
            &times;
          </button>
          <GoogleLogin callback={onGoogleLoginFinish} />
          <Divider>
            <span className="divider-text">or</span>
          </Divider>
          <Input
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Enter email address"
            style={{ marginBottom: 12 }}
          />
          <Button
            type="primary"
            className="signup-button"
            onClick={handleEmailSubmit}
          >
            Continue
          </Button>
        </div>
      )}
    </div>
  );
};

export default FooterLogin;
