// src/Login.js
import React from "react";
import googleIcon from "./assets/icon-google.png";

const GoogleLogin = ({ callback }) => {
  return (
    <button onClick={callback} className="google-login-button">
      <img src={googleIcon} alt="Google icon" className="google-login-button-icon" />
      Start free with Google
    </button>
  );
};


export default GoogleLogin;
