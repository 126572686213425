import React, { useState } from "react";

const UploadPicture = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!selectedImage) {
      alert("No file selected!");
      return;
    }

    // Example: Upload to a server endpoint
    const formData = new FormData();
    formData.append("image", selectedImage);

    try {
      const response = await fetch("/upload-endpoint", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        alert("Upload successful!");
      } else {
        alert("Upload failed!");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Upload failed!");
    }
  };

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleFileChange} />
      {previewUrl && (
        <div>
          <img
            src={previewUrl}
            alt="Selected"
            style={{ width: "300px", height: "auto" }}
          />
        </div>
      )}
      <button onClick={handleUpload}>Upload Picture</button>
    </div>
  );
};

export default UploadPicture;
