import React from "react";
import BeforeAfterSlider from "./BeforeAfterSlider";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import "./styles/CompareSlider.css";

const beforeImage = require("./assets/florent2.jpeg");
const afterImage = require("./assets/florent_with_72_virgins.png");
const ExamplePhotoSplit = () => {
  return (
    <div className="compare-slider-container">
      <ReactCompareSlider
        itemOne={<ReactCompareSliderImage src={beforeImage} alt="Image one" />}
        itemTwo={<ReactCompareSliderImage src={afterImage} alt="Image two" />}
      />
    </div>
  );
};

export default ExamplePhotoSplit;
