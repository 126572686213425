import { useEffect } from "react";

const bannerVideo = require("./assets/banner-video-480p.mov");

const VideoPlayer = () => {
  const handleVideoEnd = () => {
    console.log("Video done");
    window.collectEvent("video_ended");
  };

  return (
    <div className="video-container">
      {/* <video
        autoPlay
        playsInline
        loop // gotta disable this to active onEnded event
        muted
        // controls={false}
        controlsList="nodownload noplaybackrate"
        // // controlsList="play timeline volume"
        disablePictureInPicture
        disableRemotePlayback
        // preload={false}
        preload="auto"
        // poster=""
        onEnded={handleVideoEnd}
      > */}
      <video
        autoPlay
        playsInline
        loop
        muted
        controls={false}
        // controlsList="nodownload noplaybackrate"
        disablePictureInPicture
        disableRemotePlayback
        preload="auto"
        onEnded={handleVideoEnd}
      >
        <source src={bannerVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
