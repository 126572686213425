import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAbVx50lEGPt0V4NAeAKNz9qyYdhMXVxMU",
  authDomain: "sitin-ai-website.firebaseapp.com",
  projectId: "sitin-ai-website",
  storageBucket: "sitin-ai-website.appspot.com",
  messagingSenderId: "627021480191",
  appId: "1:627021480191:web:9adc890f497b9d42074cca",
  measurementId: "G-JF0KGN7EGS",
};

export const app = initializeApp(firebaseConfig);
