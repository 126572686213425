import { Button, message } from "antd";
import { fbqDownloadEvent } from "./facebookPixelEvents";

const DownloadSitinAppButton = () => {
  const handleClick = () => {
    const platform = navigator.platform.toLowerCase();
    if (platform.includes("mac")) {
      window.open("https://sitin.ai/SITIN.ai_5_112.dmg", "_blank");
    } else if (platform.includes("win")) {
      window.open("https://sitin.ai/SITIN.ai.msi", "_blank");
    } else {
      message.open({
        type: "info",
        content:
          "We are unable to detect the operating system you are using. Please select your download manually.",
        style: {
          marginTop: "8vh",
        },
      });
    }
    fbqDownloadEvent();
    window.collectEvent("download");
  };

  return (
    <Button onClick={handleClick} className="download-button">
      Download SITIN.ai for free
    </Button>
  );
};

export default DownloadSitinAppButton;
