// error code documentation: https://presence.feishu.cn/wiki/CCzlwNxKzilxHNk2JnUcrSKenxf?from=from_copylink
const errorMapping = {
  "auth/invalid-email": 1,
  "auth/user-disabled": 2,
  "auth/user-not-found": 3,
  "auth/wrong-password": 4,
  "auth/email-already-in-use": 5,
  "auth/weak-password": 6,
  "auth/network-request-failed": 7,
  "auth/too-many-requests": 8,
  "auth/operation-not-allowed": 9,
  "auth/requires-recent-login": 10,
  "auth/popup-closed-by-user": 11,
  "auth/popup-blocked": 12,
};

export const getErrorNumber = (errorCode) => {
  return errorMapping[errorCode] || -1;
};
