import { message } from "antd";

// import { v4 as uuidv4 } from "uuid";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import {
  doc,
  onSnapshot,
  updateDoc,
  setDoc,
  deleteDoc,
  collection,
  serverTimestamp,
  getDocs,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "./App";
import GoogleLogin from "./GoogleLogin";
import Header from "./Header";
import VideoPlayer from "./VideoPlayer";
import { useNavigate } from "react-router-dom";

/* good firestore example here:
 * https://github.com/samfromaway/firebase-tutorial/blob/master/src/SnapshotFirebaseAdvanced.js
 */

const SignupEmailPage = ({ visible, onCancel }) => {
  window.collectEvent("signup_page_loaded");

  const navigate = useNavigate();

  // firebase
  const colletionRef = collection(db, "emails");
  const googleAuthProvider = new GoogleAuthProvider();
  const auth = getAuth();

  const onGoogleLoginFinish = async (values) => {
    window.collectEvent("signup_with_google_clicked");
    const result = await signInWithPopup(auth, googleAuthProvider);
    const user = result.user;
    console.log("Google login successful. User:", user);
    // write to firestore
    const newEmail = {
      //   id: uuidv4(),
      email: user.email,
      createdAt: serverTimestamp(),
      platform: navigator.platform,
    };
    try {
      const emailRef = doc(colletionRef, newEmail.email);
      await setDoc(emailRef, newEmail);
      window.collectEvent("signup_with_google_success");
      window.collectEvent("signup_email_submitted");
    } catch (error) {
      console.error(error);
    }
    message.open({
      type: "success",
      content:
        "We have received your email and will email you updates once our app is ready.",
      duration: 5,
      style: {
        marginTop: "8vh",
      },
    });
    // window.collectEvent("subscribe form submitted");

    setTimeout(() => {
      navigate("/");
    }, 5000);
  };

  return (
    <div>
      <Header />
      <div style={{ marginTop: 80 }}>
        <div
          className="flex-center"
          style={{ marginBottom: 20, textAlign: "center" }}
        >
          <h2>Get Started for Free</h2>
          <GoogleLogin callback={onGoogleLoginFinish} />
        </div>
        <div style={{ marginBottom: 20 }}>
          <VideoPlayer />
        </div>
        <div className="desc" style={{ paddingLeft: 8, paddingRight: 8 }}>
          {/* By signing in, I agree to Sitin.ai's Terms of Service and acknowledge I
          have read the Privacy Policy. */}
          By signing in, I acknowledge I have read SITN.ai's &nbsp;
          <a
            href="./privacy-policy.html"
            style={{ textDecoration: "none", color: "#090d4e", fontSize: 14 }}
          >
            Privacy Policy
          </a>
          .
        </div>
      </div>
    </div>
  );
};

export default SignupEmailPage;
